export const COMPS_VELO_IDS = {
  DatePicker: '#datePicker',
  TimePicker: '#timePicker',
  TimeZone: '#timeZone',
  Title: '#title',
  SummaryText: '#summary',
  BoxContainer: '#appointmentBox',
  GoogleMap: '#googleMap',
} as const;

export const defaultLocation = {
  latitude: 37.77065,
  longitude: -122.387301,
  description: 'Wix Office',
};

export const bookingsLocationTypes = {
  CUSTOM: 'OWNER_CUSTOM',
  BUSINESS: 'OWNER_BUSINESS',
  CLIENT_PLACE: 'CUSTOM',
};

export const locationTypes = {
  BUSINESS_LOCATION: 'businessLocation',
  SERVICE_LOCATION: 'serviceLocation',
};
