import { onEditorReady } from './editor-app/editorReady';
import { registerEventListeners } from './editor-app/eventHandlers';
import { AppManifest } from '@wix/app-manifest-builder';
import { fetchWidgetsStageData } from '@wix/bob-widget-services';
import { WidgetComponentIds as ComponentIds } from './components/Widget1/config/components-ids';

export const editorReady = async (editorSDK, appDefId, options, flowAPI) => {
  await registerEventListeners(editorSDK, options, flowAPI);
  return onEditorReady(editorSDK, appDefId, options);
};

export const getAppManifest = async (options): Promise<AppManifest> => {
  const { appManifestBuilder } = options;
  const baseManifest = await fetchWidgetsStageData(options);
  const role = ComponentIds.googleMaps.substring(1);

  appManifestBuilder.configureWidget(
    'c5fedde3-63c7-4041-a5fb-c77bbe6308dc-pkwg7',
    (widgetBuilder) => {
      console.log({ widgetBuilder });
      widgetBuilder
        .gfpp()
        .set('settings', {
          target: {
            role,
          },
        })
        .set('design', {
          target: {
            role,
          },
        })
        .set('connect', {
          behavior: 'HIDE',
        })
        .set('help', {
          id: 'e2089a18-7a6f-4f7c-8c74-3a330c5efa4d',
        });
    },
  );

  return appManifestBuilder.withJsonManifest(baseManifest).build();
};
