import { PanelsApiFactory } from '@wix/blocks-widget-services/panels';
import { EditorReadyOptions, EditorSDK } from '@wix/platform-editor-sdk';
import { WIDGET_GFPPS } from '../constants/gfpps';
import { FlowAPI } from '@wix/yoshi-flow-editor';

let COMP_REP;

export const registerEventListeners = async (
  editorSDK: EditorSDK,
  options: EditorReadyOptions,
  flowAPI: FlowAPI,
) => {
  const panelsApi = await new PanelsApiFactory().createPanelsApi(
    editorSDK,
    options.origin.type,
  );
  await editorSDK.addEventListener('widgetGfppClicked', (event) => {
    const { id, componentRef } = event.detail;
    switch (id) {
      case WIDGET_GFPPS.SETTINGS:
        COMP_REP = componentRef;
        void panelsApi.openBlocksPanel('Settings', componentRef);
        break;
    }
  });
  await editorSDK.addEventListener('appVisitedInDashboard', (event) => {
    const { visitedAppDefIds } = event.detail;
    if (!visitedAppDefIds[0]) {
      void panelsApi.openBlocksPanel('Settings', COMP_REP);
    }
  });
};
